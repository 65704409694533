// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#subCard_CardContent__C5gMl{
    padding: 0px;
}
.subCard_maindivagga__bLhuO{
    padding: 0px 24px 0px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.subCard_cereatprogram__HukMP{
        background: linear-gradient(91.14deg, #C01A68 -8.72%, #833596 100%);
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
        border-radius: 6px;
        font-family: Poppins--500;
        font-size: 14px;
        color: #ffffff;
        width: 180px;
        height: 40px;
}`, "",{"version":3,"sources":["webpack://./src/ui-component/cards/subCard.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;AACA;IACI,yBAAyB;IACzB,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;AACA;QACQ,mEAAmE;QACnE,2CAA2C;QAC3C,kBAAkB;QAClB,yBAAyB;QACzB,eAAe;QACf,cAAc;QACd,YAAY;QACZ,YAAY;AACpB","sourcesContent":["#CardContent{\n    padding: 0px;\n}\n.maindivagga{\n    padding: 0px 24px 0px 0px;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n.cereatprogram{\n        background: linear-gradient(91.14deg, #C01A68 -8.72%, #833596 100%);\n        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);\n        border-radius: 6px;\n        font-family: Poppins--500;\n        font-size: 14px;\n        color: #ffffff;\n        width: 180px;\n        height: 40px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CardContent": `subCard_CardContent__C5gMl`,
	"maindivagga": `subCard_maindivagga__bLhuO`,
	"cereatprogram": `subCard_cereatprogram__HukMP`
};
export default ___CSS_LOADER_EXPORT___;
