import { lazy } from 'react';

// project imports
import LessonLayout from 'layout/LessonLayout';
import Loadable from 'ui-component/Loadable';
import HomeLayout from 'layout/HomeLayout';

// dashboard routing
const UserLesson = Loadable(lazy(() => import('views/user-views/lesson/UserLesson')));
const Conclusion = Loadable(lazy(() => import('views/user-views/lesson/Conclusion')));
const UserResult = Loadable(lazy(() => import('views/user-views/lesson/UserResult')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //

const LessonRoutes = {
    path: '/',
    element: <LessonLayout />,
    children: [
        {
            path: '/program/:pid',
            element: <UserLesson />
        },
        {
            path: '/program/:pid/lesson/:lid',
            element: <UserLesson />
        },
        {
            path: '/program/:pid/conclusion',
            element: <Conclusion />
        },
        {
            path: 'program/:pid/result',
            element: <UserResult />
        }
    ]
};

export default LessonRoutes;
