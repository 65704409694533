import { Outlet } from 'react-router-dom';
import { useLocation } from 'react-router';

// material-ui
import { Box, CssBaseline } from '@mui/material';

// project imports
import MainLayout from 'layout/MainLayout';

import Customization from '../Customization';
import styles from './layout.module.scss';

// ==============================|| MAIN LAYOUT ||============================== //

const ChallengesLayout = () => {
    const location = useLocation();

    if (location.pathname !== '/create-challenge') {
        return (
            <MainLayout />
        )
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <main className={styles.main}>
                <Outlet />
            </main>
            <Customization />
        </Box>
    );
};

export default ChallengesLayout;
