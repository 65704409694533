import { lazy } from 'react';

// project imports
import ChallengesLayout from 'layout/ChallengesLayout';
import Loadable from 'ui-component/Loadable';

// blog routing
const Challenges = Loadable(lazy(() => import('views/Challenges')));
const ChallengeDetail = Loadable(lazy(() => import('views/Challenges/Details')));
const StartedLesson = Loadable(lazy(() => import('views/Challenges/Details/StartedLesson')));
const CreateChallenge = Loadable(lazy(() => import('views/Challenges/CreateChallenge')))

import StepsDataContext from 'views/Challenges/CreateChallenge/comps/StepsDataContext';

// ==============================|| MAIN ROUTING ||============================== //

const ChallengesRoutes = {
    path: '/',
    element: <ChallengesLayout />,
    children: [
        {
            path: '/challenges',
            element: <Challenges />
        },
        {
            path: '/challenges/detail/:id',
            element: <ChallengeDetail />
        },
        {
            path: '/challenges/detail/:id/lesson/:lessonId',
            element: <StartedLesson />
        },
        {
            path: '/create-challenge',
            element: <StepsDataContext><CreateChallenge /></StepsDataContext>
        }
    ]
};

export default ChallengesRoutes;
