// material-ui
import {Typography} from "@mui/material";
import {IconTypography, IconPalette, IconShadow, IconWindmill, IconNotebook} from "@tabler/icons";
// project imports
import NavGroup from "./NavGroup";
import menuItem from "lesson-items";
import {useEffect} from "react";
import axiosClient from "axios-client";
import {useState} from "react";
import {useParams} from "react-router";

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const [lesson, setLesson] = useState(null);
    const icons = {
        IconTypography,
        IconPalette,
        IconShadow,
        IconWindmill,
        IconNotebook
    };
    const {pid} = useParams();
    useEffect(() => {
        if (lesson === null && pid) {
            axiosClient.get(`get/program/detail/${pid}/`).then(({data}) => {
                let ary = [];
                data.lessons.forEach((element) => {
                    ary.push({
                        id: `lesson${element.id}`,
                        title: element.name,
                        type: "item",
                        url: `program/${pid}/lesson/${element.id}`,
                        // icon: icons.IconShadow,
                        breadcrumbs: false
                    });
                });
                setLesson({
                    items: [
                        {
                            id: "programs",
                            // title: 'Programs',
                            type: "group",
                            children: ary
                        }
                    ]
                });
            });
        }
    }, [lesson]);
    console.log(lesson);
    const navItems =
        lesson !== null &&
        lesson.items.map((item) => {
            console.log(item, "$$$$$$$$$$$$$$$$$$$$$");
            switch (item.type) {
                case "group":
                    return <NavGroup key={item.id} item={item} />;
                default:
                    return (
                        <Typography key={item.id} variant="h6" color="error" align="center">
                            Menu Items Error
                        </Typography>
                    );
            }
        });

    return <>{navItems}</>;
};

export default MenuList;
