import PropTypes from "prop-types";

// material-ui
import {useTheme} from "@mui/material/styles";
import {Divider, List, Typography} from "@mui/material";

// project imports
import NavItem from "../NavItem";
import NavCollapse from "../NavCollapse";
import {includes} from "lodash";
import {useLocation} from "react-router";

// ==============================|| SIDEBAR MENU LIST GROUP ||============================== //

const NavGroup = ({item}) => {
    const theme = useTheme();
    const location = useLocation();
    // menu list collapse & items
    let currentPartId = location?.pathname?.split("/lesson/")?.[1];
    let currentPartNumber = currentPartId
        ? parseInt(item?.children?.find((child) => child?.id?.includes(currentPartId))?.title?.split(" ")?.[1])
        : 1;
    const items = item?.children?.map((menu, index) => {
        let enabledParts = Array.from({length: currentPartNumber})?.map((v, index) => index + 1);

        switch (menu.type) {
            case "collapse":
                return <NavCollapse key={menu.id} menu={{...menu, disabled: !enabledParts?.includes(index + 1)}} level={1} />;
            case "item":
                return (
                    <NavItem
                        key={menu.id}
                        item={{
                            ...menu,
                            disabled: !enabledParts?.includes(index + 1)
                        }}
                        level={1}
                    />
                );
            default:
                return (
                    <Typography key={menu.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return (
        <>
            <List
                subheader={
                    item.title && (
                        <Typography variant="caption" sx={{...theme.typography.menuCaption}} display="block" gutterBottom>
                            {item.title}
                            {item.caption && (
                                <Typography variant="caption" sx={{...theme.typography.subMenuCaption}} display="block" gutterBottom>
                                    {item.caption}
                                </Typography>
                            )}
                        </Typography>
                    )
                }
            >
                {items}
            </List>

            {/* group divider */}
            <Divider sx={{mt: 0.25, mb: 1.25}} />
        </>
    );
};

NavGroup.propTypes = {
    item: PropTypes.object
};

export default NavGroup;
