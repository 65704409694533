import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const ProgramList = Loadable(lazy(() => import('views/program/ProgramList')));
const ProgramType = Loadable(lazy(() => import('views/program/ProgramType')));
const AddProgram = Loadable(lazy(() => import('views/program/AddProgram')));
const EditProgram = Loadable(lazy(() => import('views/program/EditProgram')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: 'program',
            children: [
                {
                    path: 'program-list',
                    element: <ProgramList />
                },
                {
                    path: 'add-program',
                    element: <ProgramType />
                },
                {
                    path: 'edit-program/:id',
                    element: <EditProgram />
                }
            ]
        }
    ]
};

export default MainRoutes;
