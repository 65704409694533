import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import jwt from 'jwt-decode';

import React, { useState } from 'react';
// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery, Grid } from '@mui/material';
import LogoSection from './LogoSection';
// project imports
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Header from './Header';
import Sidebar from './Sidebar';
import Customization from '../Customization';
import navigation from 'menu-items';
import { drawerWidth } from 'store/constant';
import { SET_MENU } from 'store/actions';
import styles from './Sidebar/sidebar.module.css'
// assets
import { IconChevronRight } from '@tabler/icons';
import { useEffect } from 'react';
import axiosClient2 from 'axios-client2';
import axiosClient from 'axios-client';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    ...theme.typography.mainContent,
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: -(drawerWidth - 20),
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px',
            marginRight: '10px'
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px'
        }
    })
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [check, setCheck] = useState(false);
    const [sidebarlogo, setSidebarlogo] = useState('9.5')
    const [sidebarlogo2, setSidebarlogo2] = useState('2.5')
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    // Handle left drawer
    const leftDrawerOpened = useSelector((state) => state.customization.opened);
    const dispatch = useDispatch();
    const handleLeftDrawerToggle = () => {
        dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
    };

    // replace_token_here
    const accessToken = localStorage.getItem('access_token');

    useEffect(() => {
        setUser()
    }, [accessToken]);

    const setUser = async () => {
        if (accessToken) {
            const jwtDecoded = await jwt(accessToken);
            try {
                const res = await axiosClient.get(`/get/user/${jwtDecoded?.email}`);
                localStorage.setItem('user_info', JSON.stringify(res.data));
                localStorage.setItem('persist', true);
            } catch (err) {
                console.log('User Error: ', err);
            }
        }
    }

    

    useEffect(() => {
        axiosClient2.get('/users').then(({ data }) => {
            console.log('Access Token');
            console.log(data);
            localStorage.setItem('user_id', data.data.user_id);
            localStorage.setItem('user_role', data.data.coach_status);
            localStorage.setItem('persist', true);
            if (data.data.user_id) {
                if (data.data.coach_status) {
                    if (data.data.coach_status === 'APPROVED') {
                        setCheck(true);
                    } else {
                        window.location.href = 'https://wellavi-web1.herokuapp.com/feed';
                    }
                } else {
                    window.location.href = 'https://wellavi-web1.herokuapp.com/feed';
                }
            } else {
                window.location.href = 'https://wellavi-web1.herokuapp.com/feed';
            }
        })
    }, []);

    return (
        // --- true --- for testing & add --- check --- after testing
        check && (
            <Grid container>
                {/* <Box sx={{ display: 'flex' }}> */}
                {/* <CssBaseline /> */}

                {/* drawer */}
                <Grid item md={'2.1'} style={{ top: '0px' }}>
                    {/* <CssBaseline /> */}
                    <Sidebar data={setSidebarlogo} datanave={setSidebarlogo2} drawerOpen={!matchDownMd ? leftDrawerOpened : !leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />
                </Grid>
                {/* main content */}
                <Grid item md={'9.9'}>

                    <main className={'main'} >
                        <Toolbar className={styles.toolbarmaindiv}>
                            <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
                        </Toolbar>
                        {/* </AppBar> */}

                        <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
                        <Outlet />

                        <Customization />
                    </main>
                </Grid>


                {/* </Box> */}
            </Grid>

        )
    )

};

export default MainLayout;
