// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blogLayout_blogMain__njxdZ {
  margin-top: 67px;
  width: 100%;
  background: #fff6f4;
  padding: 15px 23px;
  font-family: "Poppins", sans-serif;
  min-height: calc(100vh - 67px);
  background: #F9FAFB;
}`, "",{"version":3,"sources":["webpack://./src/layout/BlogLayout/blogLayout.module.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,WAAA;EACA,mBAAA;EACA,kBAAA;EACA,kCAAA;EACA,8BAAA;EACA,mBAAA;AACJ","sourcesContent":[".blogMain{\n    margin-top: 67px;\n    width: 100%;\n    background: #fff6f4;\n    padding: 15px 23px;\n    font-family: 'Poppins', sans-serif;\n    min-height: calc(100vh - 67px);\n    background: #F9FAFB;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blogMain": `blogLayout_blogMain__njxdZ`
};
export default ___CSS_LOADER_EXPORT___;
