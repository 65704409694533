import { lazy } from 'react';

// project imports
import BlogLayout from 'layout/BlogLayout';
import Loadable from 'ui-component/Loadable';

// blog routing
const ViewBlog = Loadable(lazy(() => import('views/Blog')));
const CreateBlog = Loadable(lazy(() => import('views/Blog/CreateBlog')));
const SingleBlogPost = Loadable(lazy(() => import('views/Blog/SingleBlogPost')));

// ==============================|| MAIN ROUTING ||============================== //


const BlogRoutes = {
    path: '/',
    element: <BlogLayout />,
    children: [
        {
            path: '/blog',
            element: <ViewBlog />
        },
        {
            path: '/create-publication',
            element: <CreateBlog />
        },
        {
            path: '/blog/:id',
            element: <SingleBlogPost />
        }
    ]
};

export default BlogRoutes;
