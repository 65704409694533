import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import LessonRoutes from './LessonRoutes';
import HomeRoutes from './HomeRoutes';
import BlogRoutes from './BlogRoutes';
import ChallengesRoutes from './ChallengesRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([
        HomeRoutes,
        MainRoutes,
        AuthenticationRoutes,
        LessonRoutes,
        BlogRoutes,
        ChallengesRoutes
    ]);
}
