import axios from 'axios';

const axiosClient2 = axios.create({
    baseURL: `${process.env.REACT_APP_WELLIO_API_KEY}/v1`
});

axiosClient2.interceptors.request.use((config) => {
    const token = localStorage.getItem('access_token');
    console.log({token})
    config.headers['Authorization'] = `Bearer ${token}`;
    config.headers['Access-Control-Allow-Origin'] = '*';
    config.headers['Access-Control-Allow-Methods'] = 'GET, POST, PUT, DELETE';
    config.headers['Access-Control-Allow-Headers'] = 'Content-Type, Authorization';

    return config;
});

axiosClient2.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        const { response } = error;
        if (response.status === 401) {
            localStorage.removeItem('access_token');
            window.location.href = 'https://app.wellavi.com'
        }
        throw error;
    }
);

export default axiosClient2;
