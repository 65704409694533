// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../styles/popins/Poppins-Medium.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: Poppins-Medium;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}


.sidebar_toolbarmaindiv__EiHwd {
    padding: 10px 0px 15px 0px;
}

.sidebar_btninhomre__u6hvS {
    /* background-color: red; */
    background-color: rgb(243, 244, 246);
    border-radius: 6px;
    /* color: rgb(17, 24, 39) !important; */
align-items: center;
}

.sidebar_btninhomre__u6hvS p {
    font-family: Poppins-Medium;
}
.sidebar_coloriconn__DRdcF{
    color: rgb(156,163,175);
}
.sidebar_btninhomre__u6hvS:hover {
    background-color: rgb(243, 244, 246) !important;
    color: rgb(17, 24, 39);
}

.sidebar_btninhomre__u6hvS svg:hover {
    color: rgb(156,163,175) !important;

}

.sidebar_Drawerlist__ahkL5 {
    width: 238px;
}

.sidebar_listicinn__m9grM svg:hover {
    color: rgb(156,163,175) !important;

}`, "",{"version":3,"sources":["webpack://./src/layout/MainLayout/Sidebar/sidebar.module.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,4CAAqD;AACzD;;;AAGA;IACI,0BAA0B;AAC9B;;AAEA;IACI,2BAA2B;IAC3B,oCAAoC;IACpC,kBAAkB;IAClB,uCAAuC;AAC3C,mBAAmB;AACnB;;AAEA;IACI,2BAA2B;AAC/B;AACA;IACI,uBAAuB;AAC3B;AACA;IACI,+CAA+C;IAC/C,sBAAsB;AAC1B;;AAEA;IACI,kCAAkC;;AAEtC;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,kCAAkC;;AAEtC","sourcesContent":["@font-face {\n    font-family: Poppins-Medium;\n    src: url('../../../styles/popins/Poppins-Medium.ttf');\n}\n\n\n.toolbarmaindiv {\n    padding: 10px 0px 15px 0px;\n}\n\n.btninhomre {\n    /* background-color: red; */\n    background-color: rgb(243, 244, 246);\n    border-radius: 6px;\n    /* color: rgb(17, 24, 39) !important; */\nalign-items: center;\n}\n\n.btninhomre p {\n    font-family: Poppins-Medium;\n}\n.coloriconn{\n    color: rgb(156,163,175);\n}\n.btninhomre:hover {\n    background-color: rgb(243, 244, 246) !important;\n    color: rgb(17, 24, 39);\n}\n\n.btninhomre svg:hover {\n    color: rgb(156,163,175) !important;\n\n}\n\n.Drawerlist {\n    width: 238px;\n}\n\n.listicinn svg:hover {\n    color: rgb(156,163,175) !important;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toolbarmaindiv": `sidebar_toolbarmaindiv__EiHwd`,
	"btninhomre": `sidebar_btninhomre__u6hvS`,
	"coloriconn": `sidebar_coloriconn__DRdcF`,
	"Drawerlist": `sidebar_Drawerlist__ahkL5`,
	"listicinn": `sidebar_listicinn__m9grM`
};
export default ___CSS_LOADER_EXPORT___;
