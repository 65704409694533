import PropTypes from "prop-types";
import {forwardRef} from "react";

// material-ui
import {useTheme} from "@mui/material/styles";
import {Button, Card, CardContent, CardHeader, Divider, Typography} from "@mui/material";
import styles from "./subCard.module.css";
// constant
const headerSX = {
    "& .MuiCardHeader-action": {mr: 0},
    ".MuiCardHeader-content": {
        width: "100%",
        display: "flex",
        justifyContent: "between"
    }
};

// ==============================|| CUSTOM MAIN CARD ||============================== //

const MainCard = forwardRef(
    (
        {
            border = true,
            boxShadow,
            children,
            content = true,
            contentClass = "",
            contentSX = {},
            darkTitle,
            secondary,
            shadow,
            sx = {},
            title,
            subheader,
            ...others
        },
        ref
    ) => {
        const theme = useTheme();

        return (
            <Card
                ref={ref}
                {...others}
                sx={{
                    border: border ? "1px solid" : "none",
                    borderColor: theme.palette.primary[200] + 25,
                    ":hover": {
                        boxShadow: boxShadow ? shadow || "0 2px 14px 0 rgb(32 40 45 / 8%)" : "inherit"
                    },
                    ...sx
                }}
            >
                {/* card header and action */}
                <div
                    style={{marginRight: "320px"}}
                    // className={styles.maindivagga}
                >
                    {title && (
                        <CardHeader
                            className="pagetitle"
                            sx={headerSX}
                            title={darkTitle ? <Typography variant="h3">{title}</Typography> : title}
                            action={secondary}
                            subheader={subheader && subheader}
                        />
                    )}
                    {title == "Coaching Videos" ? (
                        <Button style={{width: "100"}} className={styles.cereatprogram} href="/program/add-program">
                            Create Video
                        </Button>
                    ) : (
                        ""
                    )}
                </div>
                {/* content & header divider */}
                {title && <Divider />}

                {/* card content */}
                {content && (
                    <CardContent sx={contentSX} className={contentClass}>
                        {children}
                    </CardContent>
                )}
                {!content && children}
            </Card>
        );
    }
);

MainCard.propTypes = {
    border: PropTypes.bool,
    boxShadow: PropTypes.bool,
    children: PropTypes.node,
    content: PropTypes.bool,
    contentClass: PropTypes.string,
    contentSX: PropTypes.object,
    darkTitle: PropTypes.bool,
    secondary: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
    shadow: PropTypes.string,
    sx: PropTypes.object,
    title: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object])
};

export default MainCard;
