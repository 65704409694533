// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main{
    /* margin-top: 67px; */
    background: #fff6f4;
    padding: 15px 23px;
    font-family: 'Poppins', sans-serif;
    min-height: calc(100vh - 67px);
    background: #F9FAFB;
}`, "",{"version":3,"sources":["webpack://./src/layout/HomeLayout/home-layout.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,mBAAmB;IACnB,kBAAkB;IAClB,kCAAkC;IAClC,8BAA8B;IAC9B,mBAAmB;AACvB","sourcesContent":[".main{\n    /* margin-top: 67px; */\n    background: #fff6f4;\n    padding: 15px 23px;\n    font-family: 'Poppins', sans-serif;\n    min-height: calc(100vh - 67px);\n    background: #F9FAFB;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
