// assets
import { IconTypography, IconPalette, IconShadow, IconWindmill, IconNotebook } from '@tabler/icons';

// constant
const icons = {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill,
    IconNotebook
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const Program = {
    id: 'programs',
    title: 'Programs',
    type: 'group',
    children: [
        {
            id: 'programs-list',
            title: 'Coaching Videos',
            type: 'collapse',
            icon: icons.IconNotebook,
            url: '/program/program-list'
        },
        {
            id: 'blog',
            title: 'Short-Form Content',
            type: 'collapse',
            icon: icons.IconTypography,
            url: '/blog'
        },
        {
            id: 'challenges',
            title: 'Challenges',
            type: 'collapse',
            icon: icons.IconWindmill,
            url: '/challenges'
        }
    ]
};

export default Program;
