import { useContext, useState, createContext } from 'react';

const StateContext = createContext({
    user: null,
    token: null,
    notification: null,
    setUser: () => {},
    setToken: () => {},
    setNotification: () => {}
});

export const ContextProvider = ({ children }) => {
    const [user, setUser] = useState({});
    const [token, _setToken] = useState(localStorage.getItem('REACT_ACCESS_TOKEN_REAL_ESTATE'));
    const [notification, _setNotification] = useState('');

    const setToken = (token) => {
        _setToken(token);
        if (token) {
            localStorage.setItem('REACT_ACCESS_TOKEN_REAL_ESTATE', token);
        } else {
            localStorage.removeItem('REACT_ACCESS_TOKEN_REAL_ESTATE');
        }
    };

    const setNotification = (st, message) => {
        _setNotification([st, message]);
        setTimeout(() => {
            _setNotification('');
        }, 3000);
    };

    return (
        <StateContext.Provider
            value={{
                user,
                token,
                setToken,
                notification,
                setUser,
                setNotification
            }}
        >
            {children}
        </StateContext.Provider>
    );
};

export const useStateContext = () => useContext(StateContext);

export default ContextProvider;
