// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../styles/popins/Poppins-Medium.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: Poppins-Medium;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
.header_boxsershbox__u7Rra{
    width: 100%;
    margin-left: 0px;
    background: white;
    border-radius: 0px;
}
.header_boxsershbox__u7Rra fieldset{
    border-radius: 0px;
    border: 0px !important;
}
.header_boxsershbox__u7Rra input{
    font-family: Poppins-Medium;
    font-size: 14px;
    color: #111827;
}
.header_avatarinnoti__sY354{
    width: 50px;
    border-radius: 50%;
    height: 50px;
    background-color: rgb(156, 163,175);
}
.header_tconavtar__LU9Ge{
    color: white;
}
.header_avatarinnoti__sY354:hover{
    background-color: rgb(156, 163,175);
}
.header_Avataraccountmenu__-9pWS{
    background-color: red;
    height: 50px;
    width: 50px;
}`, "",{"version":3,"sources":["webpack://./src/layout/MainLayout/Header/header.module.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,4CAAqD;AACzD;AACA;IACI,WAAW;IACX,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;AACtB;AACA;IACI,kBAAkB;IAClB,sBAAsB;AAC1B;AACA;IACI,2BAA2B;IAC3B,eAAe;IACf,cAAc;AAClB;AACA;IACI,WAAW;IACX,kBAAkB;IAClB,YAAY;IACZ,mCAAmC;AACvC;AACA;IACI,YAAY;AAChB;AACA;IACI,mCAAmC;AACvC;AACA;IACI,qBAAqB;IACrB,YAAY;IACZ,WAAW;AACf","sourcesContent":["@font-face {\n    font-family: Poppins-Medium;\n    src: url('../../../styles/popins/Poppins-Medium.ttf');\n}\n.boxsershbox{\n    width: 100%;\n    margin-left: 0px;\n    background: white;\n    border-radius: 0px;\n}\n.boxsershbox fieldset{\n    border-radius: 0px;\n    border: 0px !important;\n}\n.boxsershbox input{\n    font-family: Poppins-Medium;\n    font-size: 14px;\n    color: #111827;\n}\n.avatarinnoti{\n    width: 50px;\n    border-radius: 50%;\n    height: 50px;\n    background-color: rgb(156, 163,175);\n}\n.tconavtar{\n    color: white;\n}\n.avatarinnoti:hover{\n    background-color: rgb(156, 163,175);\n}\n.Avataraccountmenu{\n    background-color: red;\n    height: 50px;\n    width: 50px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"boxsershbox": `header_boxsershbox__u7Rra`,
	"avatarinnoti": `header_avatarinnoti__sY354`,
	"tconavtar": `header_tconavtar__LU9Ge`,
	"Avataraccountmenu": `header_Avataraccountmenu__-9pWS`
};
export default ___CSS_LOADER_EXPORT___;
