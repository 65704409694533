import React, { useState, createContext } from 'react';

import jwt from 'jwt-decode'

export const StepsContext = createContext();

const StepsDataContext = ({ children }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [errors, setErrors] = useState({});
  const [alert, setAlert] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const user = jwt(localStorage.getItem('access_token'));
  const [payload, setPayload] = useState(
    {
      user: user?.email,
      title: '',
      description: '',
      cover_photo: '',
      tags: '',
      schedule: '',
      schedule_time: ''
    }
  );
  // parts data
  const [parts, setParts] = useState([
    {
      id: 1,
      title: "",
      description: "",
      cover_photo: "",
      tasks: []
    }
  ]);


  return (
    <StepsContext.Provider
      value={{
        currentStep,
        setCurrentStep,
        payload,
        setPayload,
        errors,
        setErrors,
        parts,
        setParts,
        alert,
        setAlert,
        selectedTags,
        setSelectedTags,
        editMode,
        setEditMode
      }}
    >
      {children}
    </StepsContext.Provider>
  );
};

export default StepsDataContext;
