import {useSelector} from "react-redux";

import {ThemeProvider} from "@mui/material/styles";
import {CssBaseline, StyledEngineProvider} from "@mui/material";
import {ToastContainer, toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// routing
import Routes from "routes";

// defaultTheme
import themes from "themes";

// project imports
import NavigationScroll from "layout/NavigationScroll";
import {useStateContext} from "contexts/ContextProvider";
import {useEffect} from "react";
import axiosClient2 from "axios-client2";

// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state) => state.customization);
    const {notification, setUser, user} = useStateContext();
    useEffect(() => {
        if (notification) {
            if (notification.length > 0) {
                if (notification[0] === 200) {
                    toast.success(notification[1], {
                        position: "bottom-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored"
                    });
                } else {
                    toast.error(notification[1], {
                        position: "bottom-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored"
                    });
                }
            }
        }
    }, [notification]);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const access_token = urlParams.get("token");
        if (!localStorage.getItem("access_token")) {
            if (access_token) {
                localStorage.setItem("access_token", access_token);
                localStorage.setItem("persist", true);
            } else {
                window.location.href = "https://app.wellavi.com";
            }
        }
        console.log("called");
        axiosClient2
            .get("/users")
            .then(({data}) => {
                console.log("Access Token");
                console.log(data);
                setUser(data.data);
                localStorage.setItem("user_id", data.data.user_id);
                localStorage.setItem("user_role", data.data.coach_status);
                localStorage.setItem("persist", true);
            })
            .catch((data) => {
                console.log({data});
                console.log("Access Token Error");
                localStorage.removeItem("user_id");
                localStorage.removeItem("user_role");
                console.log(data?.response?.status);
            });
    }, []);
    console.log(user);
    return (
        <>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={themes(customization)}>
                    {/* <CssBaseline /> */}
                    <NavigationScroll>
                        <Routes />
                    </NavigationScroll>
                </ThemeProvider>
            </StyledEngineProvider>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                them
            />
        </>
    );
};

export default App;
